import "babel-polyfill";

var Modules = {
    Accordions: require('./modules/ModuleAccordions').default,
	Calendar: require('./modules/ModuleCalendar').default,
	Categories: require('./modules/ModuleCategories').default,
	ChangeRegion: require('./modules/ModuleChangeRegion').default,
	Dismissible: require('./modules/ModuleDismissible').default,
	EnterTriggerClick: require('./modules/ModuleEnterTriggerClick').default,
	Header: require('./modules/ModuleHeader').default,
	HeaderMobile: require('./modules/ModuleHeaderMobile').default,
	InputRedirectParam: require('./modules/ModuleInputRedirectParam').default,
    Interceptor: require('./modules/ModuleInterceptor').default,
	PopupHtml: require('./modules/ModulePopupHtml').default,
	ScrollTo: require('./modules/ModuleScrollTo').default,
	SelectRedirect: require('./modules/ModuleSelectRedirect').default,
	Survey: require('./modules/ModuleSurvey').default,
	Tabs: require('./modules/ModuleTabs').default,
	MemberDirectory: require('./modules/ModuleMemberDirectory').default,
	SimplyCountable: require('./libs/simplyCountable.js')
};

var Pages = {
	Default: require('./pages/PageDefault').default
};

$(function () {
    window.dom = {
        body: $('body'),
        document: $(document),
        html: $('html'),
        window: $(window)
    };

    initClasses(window.dom.body);
});

window.initClasses = function (context) {
    let pages = context[0].querySelectorAll('[data-page]');
    let modules = context[0].querySelectorAll('[data-module]');

    for (let el of pages) {
        let list = el.getAttribute('data-page').split(/\s+/);
        for (let name of list) {
            if (Pages[name] !== undefined) {
                new Pages[name]($(el)).init();
            }
        }
    }

    for (let el of modules) {
        let list = el.getAttribute('data-module').split(/\s+/);
        for (var name of list) {
            if (Modules[name] !== undefined) {
                new Modules[name]($(el)).init();
            }
        }
    }
};


