import Module from './Module';

export default class ModuleExample extends Module {
    constructor(el) {
        super(el);

        //-- Properties
        //--------------------------------------------------------------
        $.extend( true, this.dom, {
            calendar: this.dom.el.find('.js-calendar'),
            from: this.dom.el.find('.js-from'),
            to: this.dom.el.find('.js-to'),
            hidden: {
                from: this.dom.el.find('.js-from-hidden'),
                to: this.dom.el.find('.js-to-hidden')
            },
            filter: this.dom.el.find(".js-filter")
        });

        this.lang = kafe.env('lang');
        this.actualDate = new Date();
        this.dates = this.dom.el.data('dates').replace(/'/g,"").split(",");
        this.dateFormat = 'dd/mm/yy';
        this.f = 'from';
    }

    //-- Methods
    //--------------------------------------------------------------
    init() {
        this.bindEvents();
        this.setLang();
        this.setCalendar();
        this.setFrom();
        this.setTo();
    }

    bindEvents() {
        this.dom.filter.on('click', (e) => this.filter(e) );
        this.dom.from.on('focus', () => this.f = 'from' );
        this.dom.to.on('focus', () => this.f = 'to');
        // Set hidden field after manual textbox value change.
        this.dom.from.on('change', () => this.setHiddenFromInput() );
        this.dom.to.on('change', () => this.setHiddenFromInput() );
    }

    setHiddenFromInput() {
        let time = $.datepicker.parseDate(this.dateFormat, this.dom[this.f].val());
        this.dom.hidden[this.f].val(time.getTime());
        this.dom.calendar.datepicker("refresh");
    }

    setLang() {
        if ( this.lang === 'fr' ) {
            $.datepicker.regional.fr = {
                monthNames: ['Janvier','Février','Mars','Avril','Mai','Juin','Juillet','Août','Septembre','Octobre','Novembre','Décembre'],
                monthNamesShort: ['Jan','Fév','Mar','Avr','Mai','Jun','Jul','Aoû','Sep','Oct','Nov','Déc'],
                dayNames: ['Dimanche','Lundi','Mardi','Mercredi','Jeudi','Vendredi','Samedi'],
                dayNamesShort: ['Dim','Lun','Mar','Mer','Jeu','Ven','Sam'],
                dayNamesMin: ['Di','Lu','Ma','Me','Je','Ve','Sa'],
                prevText: 'Précédent', prevStatus: 'Voir le mois précédent',
                prevJumpText: '&#x3c;&#x3c;', prevJumpStatus: 'Voir l\'année précédente',
                nextText: 'Suivant', nextStatus: 'Voir le mois suivant',
                nextJumpText: '&#x3e;&#x3e;', nextJumpStatus: 'Voir l\'année suivante',
                currentText: 'Courant', currentStatus: 'Voir le mois courant',
                todayText: 'Aujourd\'hui', todayStatus: 'Voir aujourd\'hui',
                clearText: 'Effacer', clearStatus: 'Effacer la date sélectionnée',
                closeText: 'Fermer', closeStatus: 'Fermer sans modifier',
                yearStatus: 'Voir une autre année', monthStatus: 'Voir un autre mois',
                weekText: 'Sm', weekStatus: 'Semaine de l\'année',
                dayStatus: '\'Choisir\' le DD d MM',
                defaultStatus: 'Choisir la date',
                isRTL: false
            };
        } else {
            this.dateFormat = 'mm/dd/yy';
        }

        $.datepicker.setDefaults( $.datepicker.regional[this.lang] );
    }

    filter(e) {
        e.preventDefault();

        let from = this.dom.hidden.from.val();
        let to = this.dom.hidden.to.val();

        window.CCMM.search.param(`?from=${from}&to=${to}`, true);
    }

    reset(field) {
        this.dom[field].val('');
        this.dom.hidden[field].val('');
    }

    getTime(field) {
        let value = this.dom.hidden[field].val();
        return parseInt( value );
    }

    setFrom() {
        let fromString = this.dom.hidden.from.val();
        let fromInt = new Date( parseInt( fromString ) );
        let fromFormatted = $.datepicker.formatDate(this.dateFormat, this.actualDate);

        if( fromString ) {
            fromFormatted = $.datepicker.formatDate(this.dateFormat, fromInt);
        } else {
            this.dom.hidden.from.val( this.actualDate.getTime() );
        }
        this.dom.from.val( fromFormatted );
    } 

    setTo() {
        let toString = this.dom.hidden.to.val();
        let toInt = new Date( parseInt( toString ) );
        let toFormatted = '';

        if( toString ) {
            toFormatted = $.datepicker.formatDate(this.dateFormat, toInt);
        } 

        this.dom.to.val(toFormatted);
    }

    setCalendar() {
        this.dom.calendar.datepicker({
            beforeShowDay: (date) => {
                for (let i = 0; i < this.dates.length; i++) {
                    if (date.getTime().toString() == this.dates[i]) {
                        if (date.getTime() >= this.getTime('from') && date.getTime() <= this.getTime('to')) {
                            return [true, "ui-state-selected date-has-event", ""];
                        } else {
                            return [true, "date-has-event", ""];
                        }
                        
                    }
                }

                // >= from && <= to
                if (date.getTime() >= this.getTime('from') && date.getTime() <= this.getTime('to')) {
                    return [true, "ui-state-selected", ""];
                }

                this.dom.body.trigger('ads:loaded');
                
                return [true, ""];
            },
            onSelect: (dateText, inst) => {
                let time = Date.parse( dateText );
                let dateFormat = this.lang === 'fr' ? '%D/%M/%Y' : '%M/%D/%Y';

                if(this.f === 'from' && this.dom.hidden.to.val() !== "") {
                    this.reset('to');
                } else if(this.f === 'to' && time < this.getTime('from')) {
                    this.reset('to');
                    this.f = 'from';
                }

                this.dom.hidden[ this.f ].val( time );
                this.dom[ this.f ].val( kafe.date.format( dateFormat, new Date(time) ) );

                this.f = this.f === 'from' ? 'to' : 'from';
            }
        });
    }
}