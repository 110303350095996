export default class Module {
    constructor(el) {

        //-- Properties
        //--------------------------------------------------------------
        this.dom = {
            el: el,
            document: $(document),
            body: $('body'),
            html: $('html'),
            site: $('#site'),
            w: $(window)
        };
    }
}
