import Page from './Page';
import throttleDebounce from 'throttle-debounce';

export default class PageDefault extends Page {
	constructor(el) {
		super(el);

		//-- Properties
		//--------------------------------------------------------------
		$.extend(this.dom, {
			layout: this.dom.el.find('.column-layout')
		});
	}

	//-- Methods
	//--------------------------------------------------------------
	init() {
	    super.init();
		this.bindEvents();
		this.moveFilterDrawer();
		this.setChosen();
		this.setLayoutHeight();
	}

	initAds() {
	    googletag.cmd.push(function() {
	        googletag.pubads().setTargeting('lang', window.language);
	        
	        for (let x=0; x<adsArr.length; x++) {
	            let ad = adsArr[x];
	            ad.slot = `/19336027/${ad.AdZoneName}`;
	            googletag.defineSlot(ad.slot, [parseInt(ad.AdWidth), parseInt(ad.AdHeight)], `div-gpt-ad-${ad.AdZoneName}`)
                    .addService(googletag.pubads());
	        }

	        googletag.enableServices();

	        googletag.pubads().addEventListener('slotRenderEnded', (e) => {
	            if (!e.isEmpty) {
	                let id = e.slot.getSlotElementId();
                    let elem = $(`#${id}`);

                    if (elem.length) {
                        let ad = elem.closest('.js-ad-wrapper');

                        if (e.size[0] === 728 && e.size[1] === 90) { // Leaderboard
                            ad.slideDown(300, () => $('body').trigger('ads:leaderboard-loaded'));
                        } else {
                        	$('#AdDiv').show();
                            ad.show();
                        }
                        
                        $('body').trigger('ads:loaded');
                    }
	            }
	        });       
	    });

	    let ads = $('[data-init-ad]');

	    for (let x = 0; x<ads.length; x++) {
	        let ad = ads.eq(x);
	        let name = ad.data('init-ad');
            
	        googletag.cmd.push(() => googletag.display(`div-gpt-ad-${name}`));
	    }

	    var gads = document.createElement('script');
	    gads.async = true;
	    gads.type = 'text/javascript';
	    var useSSL = 'https:' == document.location.protocol;
	    gads.src = (useSSL ? 'https:' : 'http:') +
            '//www.googletagservices.com/tag/js/gpt.js';
	    var node = document.getElementsByTagName('script')[0];
	    node.parentNode.insertBefore(gads, node);
	}

	bindEvents() {
		super.bindEvents();
		this.dom.w.on('load', () => this.detectParams() )
            .on('resize', throttleDebounce.debounce(300, () => { 
			    this.setLayoutHeight(); 
			    this.moveFilterDrawer();
            }));

		this.dom.body.on("ads:loaded", () => setTimeout(() => this.setLayoutHeight(), 100) )
            .one("header:loaded", throttleDebounce.debounce(300, () => this.initAds() ));
	}

	detectParams() {
		let hash = location.hash.replace('#','');
		let hashArr = hash.split('&');

		for(let x=0; x<hashArr.length; x++) {
			let h = hashArr[x];

			if( h.indexOf('=') !== -1 ) {
				let hArr = h.split('=');
				if( hArr[1] == 'openRegisterPopup') {
					this.initAction();
				}
			} else {
				this.scrollTo(h);
			}
		}
	}

	initAction() {
		$('a.btn[data-popup-src]').click();
	}

	setLayoutHeight() {
		if(this.dom.w.width() > 1023) {
			let layout = this.dom.layout.has('div');

			if( layout.length ) {
				let content = this.dom.layout.has('div').last().children('.col-left');
				let contentHeight = 0;
				let sidebar = layout.eq(0).find('.col-right__wrapper');
				let sidebarHeight = sidebar.height();

				for(let x=0; x<layout.length - 1; x++) {
					let l = layout.eq(x);
					contentHeight += l.children('.col-left').height();
				}

				if( sidebarHeight > contentHeight ) {
					let padding = sidebarHeight - contentHeight;

					content.css({
						'min-height': padding + 20
					});
				}
			}
		} else {
			$('.column-layout .col-left').css({
				'min-height': 0
			});
		}
	}

	setChosen() {
		if(!$('html').hasClass('iOS') && !$('html').hasClass('Android')) {
			$(".chosen-select").chosen({
				allow_single_deselect: true,
				disable_search_threshold: 5,
				placeholder_text_single: " "
			}).change(function (e, b) {});
		}
	}

	moveFilterDrawer() {
		if( this.dom.w.width() <= 768 ) {
			if( $('.col-right__wrapper .drawer__mobile').length ) {
				$('.drawer__mobile').detach().prependTo('.col-left');
			}
		} else {
			if( $('.col-left .drawer__mobile').length ) {
				$('.drawer__mobile').detach().prependTo('.col-right__wrapper');
			}
		}
	}

	scrollTo(hash) {
    	if(hash !== '') {
			let anchor = $(`a[name='${hash}']`);

            if (!anchor.length && anchor.length > 0) {
				anchor = $(`#${hash}`);
			}

			if( anchor.length ) {
				let pos = anchor.offset().top - $('.header-desktop .main-menu').outerHeight();

				$('html, body').animate({ 
					scrollTop: pos
				}, 500 );
			}
		}
	}
}
