import Module from './Module';

export default class ModuleTabs extends Module {
    constructor(el) {
		super(el);

		//-- Properties
		//--------------------------------------------------------------
		$.extend( true, this.dom, {
			menuItems: this.dom.el.find('.js-menu-item'),
			contents: this.dom.el.find('.js-content')
		});
	}

	//-- Methods
	//--------------------------------------------------------------
	init() {
		this.bindEvents();
		this.initContent();
	}

	initContent() {
		let index = 0;
		let elem = this.dom.menuItems.parent().children('.is-active');

		if( elem.length ) {
			index = elem.index();
		}

		this.updateContent( index );
	}

	bindEvents() {
		this.dom.menuItems.on('click', (e) => this.update(e));
	}

	update(e) {
		e.preventDefault();
		e.stopImmediatePropagation();
		let elem = $(e.delegateTarget);
		let index = elem.index();

		elem.parent().children('.is-active').removeClass('is-active');
		elem.addClass('is-active');
		this.updateContent( index );
	}

	updateContent( index ) {
		this.dom.contents.eq( index ).parent().children('.is-active').removeClass('is-active');
		this.dom.contents.eq( index ).addClass('is-active');
	}
}
