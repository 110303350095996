import Module from './Module';

export default class ModuleAccordions extends Module {
    constructor(el) {
		super(el);

		//-- Properties
		//--------------------------------------------------------------
		//$.extend( true, this.dom, {
			
		//});
	}

	//-- Methods
	//--------------------------------------------------------------
    init() {
        this.bindEvents();
    }

	bindEvents() {
        var acc = document.getElementsByClassName("accordion");
        var i;

        for (i = 0; i < acc.length; i++) {
            acc[i].addEventListener("click", function () {
                this.classList.toggle("active");
                var panel = this.nextElementSibling;
                if (panel.style.maxHeight) {
                    panel.style.maxHeight = null;
                } else {
                    panel.style.maxHeight = panel.scrollHeight + "px";
                }
            });
        }
	}

}
