﻿import Module from './Module';

export default class ModuleBootstrapHelper extends Module {
    constructor(el) {
        super(el);

        //-- Properties
        //--------------------------------------------------------------
        this.breakpoints = ["xs", "sm", "md", "lg", "xl"];
        this.consoleStyles = 'background: #6f5499; color: #fff';

        $.extend(this.dom, {
            temp: window.document.createElement("div")
        });
    }

    //-- Methods
    //--------------------------------------------------------------
    get breakpoint() {
        this.dom.body[0].appendChild( this.dom.temp );

        for (let x = this.breakpoints.length - 1; x >= 0; x--) {
            let env = this.breakpoints[x];

            this.dom.temp.className = `hidden-${env}`;

            if ( $(this.dom.temp).is(':hidden') ) {
                this.dom.temp.parentNode.removeChild( this.dom.temp );
                console.log(`%c Bootstrap: ${env} `, this.consoleStyles);
                return env;
            }
        }

        return "";
    }
}
