import Module from './Module';

export default class ModulePopupHtml extends Module {
    constructor(el) {
		super(el);

		//-- Properties
		//--------------------------------------------------------------
		this.dynamic = this.dom.el.data('popup');
	}

	//-- Methods
	//--------------------------------------------------------------
	init() {
		this.bindEvents();
	}

	bindEvents() {
		this.dom.el.on('click', (e) => this.openPopup(e) );
	}

	openPopup(e) {
		e.preventDefault();

		if( this.dynamic ) {
			this.createPopup();
		} else {
			$.extend( true, this.dom, {
				popup: $( this.dom.el.data('popup-src') ),
				get bg() {
					return this.popup.find('.popup__bg');
				}
			});
		}

		setTimeout(() => { /* Show open animation */
			this.dom.body.addClass('js-popup-is-open');
			
			if( (this.dom.html.hasClass('Mobile') || this.dom.html.hasClass('Android'))  ) {
				this.dom.body.addClass('js-scroll-is-disabled');
			} else {
				this.disableScroll();
			}
			this.dom.popup.addClass('is-open');
			this.dom.bg.on('click', () => this.closePopup() );
		}, 100);
	}

	closePopup() {
		this.dom.body.removeClass('js-popup-is-open');
		if( (this.dom.html.hasClass('Mobile') || this.dom.html.hasClass('Android'))  ) {
			this.dom.body.removeClass('js-scroll-is-disabled');
		} else {
			this.enableScroll();
		}
		
		this.dom.popup.removeClass('is-open')
			.on('transitionend webkitTransitionEnd oTransitionEnd mstransitionend', () => {
				if( this.dynamic ) { this.dom.popup.remove(); }
			});
	}

	createPopup() {
		let html = this.dom.el.data('popup');

		this.dom.site.append(`<section class="popup js-generated-popup">
			<span class="popup__bg"></span>
			<div class="popup__wrapper">
				<header class="popup__header">
					<button class="popup__close icon-clear"></button>
				</header>
				<div class="popup__scroller">
					${html}
				</div>
			</section>
		</div>`);

		$.extend( true, this.dom, {
			popup: this.dom.body.find('.js-generated-popup'),
			get bg() {
				return this.popup.find('.popup__bg');
			}
		});
	}

	disableScroll() {
		if (window.addEventListener) // older FF
			window.addEventListener('DOMMouseScroll', this.preventDefault, false);
		window.onwheel = this.preventDefault; // modern standard
		window.onmousewheel = document.onmousewheel = this.preventDefault; // older browsers, IE
		window.ontouchmove  = this.preventDefault; // mobile
		document.onkeydown  = this.preventDefaultForScrollKeys;
	}

	enableScroll() {
		if (window.removeEventListener)
			window.removeEventListener('DOMMouseScroll', this.preventDefault, false);
		window.onmousewheel = document.onmousewheel = null; 
		window.onwheel = null; 
		window.ontouchmove = null;  
		document.onkeydown = null;  
	}

	preventDefault(e) {
		e = e || window.event;
		if (e.preventDefault)
			e.preventDefault();
		e.returnValue = false;  
	}

	preventDefaultForScrollKeys(e) {
		if (keys[e.keyCode]) {
			preventDefault(e);
			return false;
		}
	}
}
