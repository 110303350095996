import Module from './Module';
import Cookies from 'js-cookie';

export default class ModuleDismissible extends Module {
    constructor(el) {
        super(el);

        //-- Properties
        //--------------------------------------------------------------
        this.dom = $.extend({}, this.dom, {
            closeBtn: el.find('button.close')
        });
        
        this.cookieName = el.attr('data-dismissible-cookie');
        this.ieOnly = el.attr('data-ie-only');
        this.cookieExpires = parseInt(el.attr('data-dismissible-cookie-expires'), 10);
    }

    //-- Methods
    //--------------------------------------------------------------
    init() {
        this.bindEvents();

        let keepHidden = false;

        if (this.cookieName) {
            let isHidden = Cookies.get(this.cookieName);

            if (isHidden === '1') { // The cookie value is a string
                keepHidden = true;
            }
        }

        if (this.ieOnly === '1' && this.ie_ver() === 0) {
            keepHidden = true;
        }

        if (!keepHidden) {
            this.dom.el.removeClass('d-none');
        }
    }

    bindEvents() {
        this.dom.closeBtn.on('click', e => this.dismissMessage(e));
    }

    dismissMessage(e) {
        e.preventDefault();
        
        this.dom.el.addClass('d-none');

        if (this.cookieName) {
            let cookieOptions = {};
            
            if (this.cookieExpires) {
                cookieOptions.expires = this.cookieExpires;
            }

            Cookies.set(this.cookieName, '1', cookieOptions);
        }

        let warningBanner = this.dom.document.find('.warningbanner');
        if (warningBanner.is(':hidden')) {
            this.dom.document.find('[data-scrollmagic-pin-spacer]').css('top', '0');
            this.dom.document.find('.header-mobile').css('top', '0');
            this.dom.document.find('main').css('top', '0');
            this.dom.document.find('.main-footer').css('top', '0');
        }
    }

    ie_ver() {
        var iev = 0;
        var ieold = (/MSIE (\d+\.\d+);/.test(navigator.userAgent));
        var trident = !!navigator.userAgent.match(/Trident\/7.0/);
        var rv = navigator.userAgent.indexOf("rv:11.0");

        if (ieold) iev = RegExp.$1;
        if (navigator.appVersion.indexOf("MSIE 10") != -1) iev = 10;
        if (trident && rv != -1) iev = 11;

        return iev;
    }
}
