/*jshint sub:true*/
import Module from './Module';

export default class ModuleCategories extends Module {
    constructor(el) {
		super(el);
		//-- Properties
		//--------------------------------------------------------------
		$.extend( true, this.dom, {
			toggle: this.dom.el.find('.js-toggle-categories'),
			list: this.dom.el.find('.categories__list')
		});
	}

	//-- Methods
	//--------------------------------------------------------------
	init() {
		this.bindEvents();
	}

	bindEvents() {
		this.dom.toggle.on('click', () => this.toggle() );
	}

	toggle() {
		this.dom.el.toggleClass('is-open');

		if( this.dom.el.hasClass('is-open') ) {
			this.dom.list.slideDown(300, () => $('body').trigger('ads:loaded') );
		} else {
			this.dom.list.slideUp(300, () => $('body').trigger('ads:loaded') );
		}
	}
}
