import Module from './Module';
import throttleDebounce from 'throttle-debounce';
import ScrollMagic from 'scrollmagic';

export default class ModuleHeader extends Module {
    constructor(el) {
		super(el);

		//-- Properties
		//--------------------------------------------------------------
		this.controller = new ScrollMagic.Controller();


		$.extend( true, this.dom, {
		    searchButton: this.dom.el.find('#btnUpperSearch'),
		    searchTextField: this.dom.el.find('.searchBoxText'),
			mask: this.dom.el.find('.header__mask'),
			menu: this.dom.el.find('.main-menu'),
			get subMenu() { 
				return this.menu.find('> ul > li').has('ul'); 
			},
			get subSubMenu() { 
				return this.menu.find('> ul > li > ul > li').has('ul'); 
			},
			otherSite: {
				btn: this.dom.el.find('.other-site__menu > a'),
				menu: this.dom.el.find('.other-site__menu'),
				get subMenu() { 
					return this.menu.find('> ul > li').has('ul'); 
				},
				get subSubMenu() {
					return this.menu.find('> ul > li > ul > li').has('ul');
				} 
			}
		});
	}

	//-- Methods
	//--------------------------------------------------------------
    init() {
		this.bindEvents();
		this.setChevron();
		this.setTop();
		this.setSticky();
        this.setSubSubMenuTop();
    }

	bindEvents() { 
		this.dom.w.on('orientationchange', () => this.updateSticky() );
        this.dom.w.on('resize', throttleDebounce.debounce(350, () => this.updateSticky()));
        this.dom.w.on('scroll', throttleDebounce.debounce(10, () => this.setSubSubMenuTop()));
        this.dom.w.on('scroll', throttleDebounce.debounce(10, () => this.hideSubSubMenuOnScroll()));
        this.dom.subMenu.on('click', (e) => this.openSubMenu( e ) );
		this.dom.subSubMenu.on('click', (e) => this.openSubSubMenu( e ) );
		this.dom.mask.on('click', () => this.closeAll() );
		this.dom.otherSite.btn.on('click', (e) => this.toggleOtherSiteMenu(e) );
		this.dom.otherSite.subMenu.on('click', (e) => this.openOtherSiteMenu( e ) );
		this.dom.searchButton.on('click', () => this.launchSearch() );
		this.dom.searchTextField.on('keyup', (e) => this.detectEnter( e.keyCode ) );
        this.dom.body.one("ads:leaderboard-loaded",
            () => {
                this.updateSticky();
                this.setSubSubMenuTop();
            });

        var noticeClose = this.dom.document.find('.warningbanner button.close');
        if (noticeClose) {
            this.dom.body.one("click", () => this.setSubSubMenuTop());
        }
        
	}

	detectEnter(keyCode) {
		if (keyCode == 13) {
			this.launchSearch();
		}
	}

    launchSearch() {
        let page = this.dom.searchTextField.data('search-page');
        let val = encodeURI( this.dom.searchTextField.val() );

        window.location.href = `${page}?q=${val}`;
		return false;
    }

	setChevron() {
		this.dom.subMenu.children('a').append('<span class="icon-chevron"></span>');
	}

	setSticky() {
		let headerHeight = this.dom.el.outerHeight();
		let menuHeight = this.dom.menu.outerHeight();
		let offset = headerHeight - menuHeight;

		this.scene = new ScrollMagic.Scene({
			triggerHook: 'onLeave',
			offset: offset
		})
		.setClassToggle('body', 'is-sticky')
		.setPin( this.dom.el[0], {pushFollowers: false})
		.addTo( this.controller );

		$('body').trigger('header:loaded');
	}

	updateSticky() {
        if( this.dom.w.width() > 1023 ) 
            this.dom.el.show();
        
        let headerHeight = this.dom.el.outerHeight();
        if( headerHeight === 0 ) { headerHeight = 201; }
        let menuHeight = this.dom.menu.outerHeight();
        let offset = headerHeight - menuHeight;

        this.scene.offset( offset )
            .refresh()
			.update(true);
	}

	openSubMenu( e ) {
		if( $(e.target).next().is('ul') ) {
			e.preventDefault();
			e.stopImmediatePropagation();
		}
		
		let elem = $(e.target).parent('li');

		if( elem.hasClass('is-open') ) {
			this.closeAll();
		} else {
			elem.siblings().removeClass('is-open').find('.is-open').removeClass('is-open');
			elem.addClass('is-open');
			this.dom.otherSite.menu.removeClass('is-open').find('.is-open').removeClass('is-open');

			this.dom.mask.addClass('is-active');
		}
	}

	openSubSubMenu( e ) {
		if( $(e.target).next().is('ul') ) {
			e.preventDefault();
			e.stopImmediatePropagation();
		}
		let elem = $(e.target).parent('li');

		elem.siblings().removeClass('is-open').find('.is-open').removeClass('is-open');
		elem.addClass('is-open');

		this.dom.mask.addClass('is-active');
		
		this.setSubSubMenuWidth( elem );
    }

    setSubSubMenuTop() {
        var height = this.GetSubMenuHeight();
        var top = height + 201 - this.dom.w.scrollTop();
        if (this.dom.document.find('.is-sticky').length > 0) {
            top = 57;
            var notice = this.dom.document.find('.warningbanner');
            if (notice && !notice.hasClass('d-none')) {
                top += notice.height();
            }
            this.dom.el.find('.desktop-submenu').addClass("sticky");
            this.dom.el.find('.desktop-submenu').css("left", this.dom.el.find('.sticky-logo').width() + 'px');
        } else {
            this.dom.el.find('.desktop-submenu').removeClass("sticky");
            this.dom.el.find('.desktop-submenu').css("left", '0');
        }
        if (top < 57) {
            top = 57;
        }
        this.dom.el.find('.desktop-submenu').css('top', top + 'px');
    }

    hideSubSubMenuOnScroll() {
        var openSub = this.dom.el.find('.is-open .desktop-submenu');
        if (this.dom.document.find('.is-sticky').length > 0 && openSub.length > 0) {
            if (this.dom.w.scrollTop() >= openSub.position().top + (openSub.height() / 2)) {
                this.dom.el.find('.main-menu > ul > li').removeClass("is-open");
                this.dom.el.find('.desktop-submenu').css("left", this.dom.el.find('.sticky-logo').width() + 'px');
            }
        } else {
            this.dom.el.find('.desktop-submenu').css("left", '0');
        }
    }

    GetSubMenuHeight() {
        var height = 0;
        var notice = this.dom.document.find('.warningbanner');
        var leaderBoard = this.dom.document.find('.leaderboard');
        if (leaderBoard) {
            height += leaderBoard.height();
        }
        if (notice && !notice.hasClass('d-none')) {
            height += notice.height();
        }
        return height;
    }

    setSubSubMenuWidth(elem) {
		if( elem.parents('.is-open').length ) {
			let ul = elem.children('ul');
			let ParentNbr = elem.parent().children().length;
			let subNbr = elem.children('ul').children().length;
			let colNbr = Math.ceil( subNbr / ParentNbr );

			ul.addClass(`has-${colNbr}-col`).css( 'width', colNbr * 350 );
		}
	}

	toggleOtherSiteMenu(e) {
		e.preventDefault();
		
		if( this.dom.otherSite.menu.hasClass('is-open') ) {
			this.closeAll();
		} else {
			this.closeAll();
			this.dom.otherSite.menu.addClass('is-open');
			this.dom.mask.addClass('is-active');
		}
	}

	openOtherSiteMenu( e ) {
		if( $(e.target).next().is('ul') ) {
			e.preventDefault();
			e.stopImmediatePropagation();
		}
		let elem = $(e.target).parent('li');

		this.setOtherSiteSubWidth( elem );
		elem.siblings().removeClass('is-open').find('.is-open').removeClass('is-open');
		elem.addClass('is-open');
	}

	setOtherSiteSubWidth(elem) {
		let ul = elem.children('ul');
		let ParentNbr = elem.parent().children().length;
		let subNbr = elem.children('ul').children().length;
		let colNbr = Math.ceil( subNbr / ParentNbr );

		ul.addClass(`has-${colNbr}-col`).css( 'width', colNbr * 350 );
	}

	setTop() {
        let warningBanner = this.dom.document.find('.warningbanner');
        if (!warningBanner.is(':hidden')) {
            this.dom.el.css('top', warningBanner.outerHeight() + 'px');
            this.dom.document.find('main').css('top', warningBanner.outerHeight() + 'px');
            this.dom.document.find('.main-footer').css('top', warningBanner.outerHeight() + 'px');
	    }
	}

	closeAll() {
		this.dom.el.find('.is-open').removeClass('is-open');
		this.dom.mask.removeClass('is-active');
	}
}
