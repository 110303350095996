import Module from './Module';

export default class ModuleSelectRedirect extends Module {
    constructor(el) {
		super(el);

		//-- Properties
		//--------------------------------------------------------------
	}

	//-- Methods
	//--------------------------------------------------------------
	init() {
		this.bindEvents();
	}

	bindEvents() {
		this.dom.el.on('change', (e) => this.redirect(e) );
	}

	redirect(e) {
		let url = e.currentTarget.value;

		if( typeof url === 'string' && url.length ) {
			window.location.href = url;
		}
	}
}
