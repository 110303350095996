import Module from './Module';

export default class ModuleScrollTo extends Module {
    constructor(el) {
		super(el);

		//-- Properties
		//--------------------------------------------------------------
		$.extend( true, this.dom, {
			target: $( this.dom.el.attr("href") )
		});

		let speed = 500;
		let offset = 10;
	}

	//-- Methods
	//--------------------------------------------------------------
	init() {
		this.bindEvents();
		this.getOffset();
	}

	bindEvents() {
		this.dom.el.on('click', (e) => this.scrollTo(e));
	}

	getOffset() {
		let offset = this.dom.el.data('offset');
		let offsetArr = offset.split(",");
		this.offset = 15;

		for( let x=0; x<offsetArr.length; x++ ) {
			this.offset += $(offsetArr[x]).outerHeight();
		}
	}

	scrollTo(e) {
		e.preventDefault();
		let pos = this.dom.target.offset().top - this.offset;

		$('html, body').animate({ 
			scrollTop: pos
		}, this.speed );
	}
}
