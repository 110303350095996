import Module from './Module';

export default class ModuleHeaderMobile extends Module {
    constructor(el) {
		super(el);

		//-- Properties
		//--------------------------------------------------------------
		$.extend( true, this.dom, {
			searchButton: this.dom.el.find('.js-search'),
			searchTextField: this.dom.el.find('.searchBoxText'),
			toggle: this.dom.el.find('.js-toggle'),
			hamburger: this.dom.el.find('.hamburger'),
			input: this.dom.el.find('input'),
			menu: this.dom.el.find('.menu'),
			get subMenu() { 
				return this.menu.find('.main-menu ul > li, .other-site ul > li').has('ul'); 
			}
		});
	}

	//-- Methods
	//--------------------------------------------------------------
	init() {
		this.setChevron();
		this.setBacks();
		this.bindEvents();
    this.setTop();
	}

	bindEvents() {
		this.dom.searchButton.on('click', (e) => {
			e.preventDefault();
			this.search();
		});

		this.dom.searchTextField.on('keydown', (e) => {
			if (e.keyCode === 13) {
				e.preventDefault();
				this.launchSearch();
				return false;
			}
		});

		this.dom.toggle.on('click', (e) => { 
			e.preventDefault(); 
			this.toggle();
		});

		this.dom.subMenu.on('click', (e) => this.openSubMenu( e ) );
		this.dom.backs.on('click', (e) => this.closeSubMenu( e ) );
	}

	launchSearch() {
		let _field = this.dom.searchTextField;
		window.location.href = _field.data('search-page') + '?q=' + encodeURI(_field.val());
	}

	setChevron() {
		this.dom.subMenu.children('a').append('<span class="icon-chevron"></span>');
	}

	setBacks() {
		for(let x=0; x<this.dom.subMenu.length; x++) {
			let elem = $(this.dom.subMenu[x]);
			let text = elem.children('.back-text').text();
			let ul = elem.children('ul');

			ul.prepend(`<li>
				<a href="#" class="back js-close-submenu">
					<span class="icon-chevron"></span>
					${text}
				</a>
			</li>`);
		}

		$.extend( true, this.dom, {
			backs: this.dom.menu.find('.js-close-submenu')
		});
	}

	search() {
		if( this.dom.el.hasClass('search-active') ) {
			this.launchSearch();
		} else {
			this.dom.el.addClass('search-active');
			this.dom.hamburger.addClass('is-active');
			this.dom.input.focus();
		}
	}

	toggle() {
		if( this.dom.el.hasClass('search-active') ) {
			this.dom.el.removeClass('search-active menu-active');
			this.dom.hamburger.removeClass('is-active');
		} else {
			if( this.dom.el.hasClass('menu-active') ) {
				this.dom.body.removeClass('js-scroll-is-disabled');
				this.dom.el.removeClass('menu-active');
				this.dom.hamburger.removeClass('is-active');
			} else {
				this.dom.el.addClass('menu-active');
				this.dom.hamburger.addClass('is-active');
				this.dom.body.addClass('js-scroll-is-disabled');
			}

			this.dom.el.removeClass('search-active');
		}
	}

	openSubMenu( e ) {
		if( $(e.target).next().is('ul') ) {
			e.preventDefault();
			e.stopImmediatePropagation();
		}
		let elem = $(e.target).parent('li');

		elem.siblings().removeClass('is-open').find('.is-open').removeClass('is-open');
		elem.addClass('is-open');

		
		$('.scroll-wrapper').animate({ 
			scrollTop: 0
		}, 100 );
	}

  setTop() {
    let warningBanner = this.dom.document.find('.warningbanner');
    if(!warningBanner.is(':hidden')) {
        this.dom.el.css('top', warningBanner.outerHeight() + 'px');
        this.dom.document.find('main').css('top', warningBanner.outerHeight() + 'px');
        this.dom.document.find('.main-footer').css('top', warningBanner.outerHeight() + 'px');
    }
  }

	closeSubMenu( e ) {
		e.preventDefault();
		e.stopImmediatePropagation();

		let elem = $(e.target).closest('.is-open');
		elem.removeClass('is-open');
	}
}
