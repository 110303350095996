import Module from './Module';
import basil from 'basil.js';

export default class ModuleInterceptor extends Module {
    constructor(el) {
		super(el);

		//-- Properties
		//--------------------------------------------------------------
		$.extend( true, this.dom, {
			bg: el.find('.popup__bg'),
			disable: el.find('.js-disable-interceptor'),
			remind: el.find('.js-remind-interceptor')
		});

		this.basil = new Basil();

		this.settings = {
        	expire: 2,
            exist: false
        };
	}

	//-- Methods
	//--------------------------------------------------------------
	init() {
		this.bindEvents();
	}

	bindEvents() {
		this.dom.bg.on('click', () => this.setCookie( this.settings.expire ) );
		this.dom.disable.on('click', () => this.setCookie() );
        this.dom.remind.on('click', () => this.setCookie( this.settings.expire ) );
	}

	setCookie(expireDays = 365) {
		this.basil.set('interceptor', false, {
			'storages': ['cookie'],
            'expireDays': expireDays
        });
        this.closePopup();
	}

	closePopup() {
		this.dom.body.removeClass('js-popup-is-open js-scroll-is-disabled');
		if( (this.dom.html.hasClass('Mobile') || this.dom.html.hasClass('Android')) ) {
		} else {
			this.enableScroll();
		}
		
		this.dom.el.removeClass('is-open');
	}

	enableScroll() {
		if (window.removeEventListener)
			window.removeEventListener('DOMMouseScroll', this.preventDefault, false);
		window.onmousewheel = document.onmousewheel = null; 
		window.onwheel = null; 
		window.ontouchmove = null;  
		document.onkeydown = null;  
	}

	preventDefault(e) {
		e = e || window.event;
		if (e.preventDefault)
			e.preventDefault();
		e.returnValue = false;  
	}
}
