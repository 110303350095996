import Module from './Module';

export default class ModuleInputRedirectParam extends Module {
    constructor(el) {
		super(el);

		//-- Properties
		//--------------------------------------------------------------
		$.extend( true, this.dom, {
			input: this.dom.el.find('input'),
			btn: this.dom.el.find('a')
		});

		this.url = this.dom.el.data('url'); 
		this.param = this.dom.el.data('param'); 
	}

	//-- Methods
	//--------------------------------------------------------------
	init() {
		this.bindEvents();
	}

	bindEvents() {
		this.dom.input.on('keydown', (e) => this.detectEnter( e ) );
		this.dom.btn.on('click', (e) => this.redirect(e) );
	}

	detectEnter(e) {
		if (e.keyCode === 13) {
			e.preventDefault();
			this.redirect(e);
		}
	}

	redirect(e) {
		e.preventDefault();
		let value = this.dom.input.val();
		let url = encodeURI( `${this.url}?${this.param}=${value}` );

        window.location.href = url;
	}
}
