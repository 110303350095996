/*jshint sub:true*/
import Module from './Module';

export default class ModuleSurvey extends Module {
    constructor(el) {
		super(el);
		//-- Properties
		//--------------------------------------------------------------
        this.surveyId = this.dom.el.data("surveyid");
        this.lang = this.dom.html.prop('lang');
	}

	//-- Methods
	//--------------------------------------------------------------
	init() {
		this._initSurvey();
	}

	_initSurvey() {
		let params = {};
		params["showresults"] = "0";

		this._showSurvey(params);
	}

	_showSurvey(additionalParams) {
		additionalParams["culture"] = this.dom.html.prop('lang');
		additionalParams["surveyId"] = this.surveyId;
		additionalParams["cookiesEnabled"] = navigator.cookieEnabled ? "1" : "0";
        let that = this;

		$.get("/layouts/Sublayout/Sidlee/Accueil/DynamicBlocks/Survey/SurveyControl.aspx", additionalParams, function (data) {
			if (data) {
                that.dom.el.html(data);

				if ($('.home-masonery').length > 0) {
					$('.home-masonery').masonry('reload');
				}

                that._initSurveyBehavior();
				$('body').trigger('ads:loaded');
			}
		}, 'html');
	}

	_initSurveyBehavior(surveyBlock) {
	    let that = this;
		this.dom.el.find(".vote").on('click', function (e) {
			e.preventDefault();

			let radio = that.dom.el.find('input:checked');
			if (radio.length > 0) {
				let params = {};
				params["culture"] = that.dom.html.prop('lang');
				params["answerId"] = radio.val();

				$.post("/services/SurveyCastVote.ashx", params, function (data) {
					if (data) {
						that._initSurvey();
					}
				});
			}
		});

		this.dom.el.find(".show-results").on('click', function (e) {
			e.preventDefault();

			let params = {};
			params["showresults"] = "1";

			that._showSurvey(params);
		});
	}
}
