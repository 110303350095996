import Module from './Module';

export default class ModuleMemberDirectory extends Module {
	constructor(el) {
		super(el);

		//-- Properties
		//--------------------------------------------------------------
		$.extend(this.dom, {
			form: this.dom.el.find('.member-search-form'),
            formSubmit: this.dom.el.find('.member-search-form .btn.submit-search')
		});
	}

	//-- Methods
	//--------------------------------------------------------------
	init() {
		this.bindEvents();
	}

	bindEvents() {
        this.dom.form.on('keypress', (e) => {
            if(e.keyCode==13){
                e.preventDefault();
                var location = this.dom.formSubmit.attr("href");
                window.location = location;
            }
        });
	}
}
