import Module from './Module';

export default class ModuleEnterTriggerClick extends Module {
    constructor(el) {
		super(el);

		//-- Properties
		//--------------------------------------------------------------
		$.extend( true, this.dom, {
			target: $( this.dom.el.data('target') )
		});
	}

	//-- Methods
	//--------------------------------------------------------------
	init() {
		this.bindEvents();
	}

	bindEvents() {
		this.dom.el.on('keydown', (e) => this.detectEnter( e ) );
	}

	detectEnter(e) {
		if (e.keyCode === 13) {
			e.preventDefault();
			e.stopImmediatePropagation();
			this.dom.target.trigger('click');
		}
	}
}
