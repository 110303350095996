import Module from './Module';

export default class ModuleChangeRegion extends Module {
    constructor(el) {
		super(el);

		//-- Properties
		//--------------------------------------------------------------
		$.extend( true, this.dom, {
		    select: this.dom.el.find('select'),
		    btn: this.dom.el.find('.js-go-to')
		});
	}

	//-- Methods
	//--------------------------------------------------------------
	init() {
		this.bindEvents();
	}

	bindEvents() {
		if( this.dom.body.hasClass('mobile') ) {
			this.dom.select.on('blur', () => this.redirect() );
		} else { 
			this.dom.select.on('change', () => this.redirect() );
		}

	    this.dom.btn.on('click', () => this.redirect());
	}

	redirect() {
		let url = this.dom.select.val();

		if( url !== '' ) {
			if( url.indexOf("/") === 0 )
				window.location.href = url;
			else
				window.open(url, '_blank');
		}
	}
}